.containerError {
  background-color: red;
}

.ucclogo {
  width: 60%;
}

.vertical-center {
  margin-left: -320px;
  overflow-x: hidden;
  min-height: 80%;
  min-height: 80vh;
  position: absolute;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.31);
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-custome {
  border-right: 1px solid #ccc;
}

.container-custome {
  -webkit-box-shadow: -4px 4px 19px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -4px 4px 19px -8px rgba(0, 0, 0, 0.75);
  box-shadow: -4px 4px 19px -8px rgba(0, 0, 0, 0.75);
}

.error-title {
  font-size: 80px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.error-description {
  font-size: 20px;
}

@media (max-width: 576px) {

  .error-title {
    font-size: 40px;
  }

  .error-description {
    font-size: 16px;
    text-align: center;
  }


  .border-custome {
    border-right: none;
  }

}